import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from '../Panel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { changeAppStatus } from "../../Redux/features/commonSlice";
import * as Config from '../../data/Config';
import { $L } from '../../utils/common';

import MultilingualDropdown from './MultilingualDropdown/MultilingualDropdown';
import Logo from '../../assets/img/logo.png';
import MultilingualIcon from '../..//assets/img/multilingualIcon.png';
import css from './Gnb.module.scss';
import * as RouterPath from '../../data/RouterPath';


const mConfig = Config.getConfig();

const Gnb = ({ onAboutClick, onPortfolioClick, ...props }) => {

  //스크롤 헤더 배경
  window.addEventListener("scroll", () => {
    let scrollTop = document.documentElement.scrollTop || window.scrollY || window.pageYOffset;
    const top = document.querySelector("header");
  });

  const dispatch = useDispatch();

  const multilingual = [
    {
      name: 'English', lang: 'en'
    },
    {
      name: '한국어', lang: 'ko'
    }
  ]

  const [langIdx, setLangIndex] = useState(0);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const onScroll = useCallback(() => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  });
  // const onScroll = useCallback(() => {
  //   let scrollTop = document.documentElement.scrollTop || window.scrollY || window.pageYOffset;
  //   const top = document.querySelector("header");
  //   // if (scrollTop > 100) {
  //   //   top.style.cssText = 'background-color: rgba(0, 0, 0, 0.432); transition: all 0.2s;';
  //   // } else {
  //   //   top.style.cssText = 'background-color: rgba(0, 0, 0, 0); transition: all 0.2s;';
  //   // }
  // }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onSelectLanguage = useCallback((index) => {
    setLangIndex(index);
    dispatch(changeAppStatus({ language: multilingual[index].lang }));
    mConfig.setLanguage(multilingual[index].lang);
  }, []);

  const _onSelectLanguage = useCallback((ev) => {
    if (ev && ev.target && ev.target.value) {
      setDropdownVisibility(dropdownVisibility => !dropdownVisibility);
      if (onSelectLanguage) {
        onSelectLanguage(ev.target.value);
      }
    }
  }, [dropdownVisibility, onSelectLanguage]);

  const setDropdown = useCallback((ev) => {
    setDropdownVisibility(dropdownVisibility => !dropdownVisibility);
  }, [dropdownVisibility]);

  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
  }, [location])

  const navigate = useNavigate();

  const navigateToportfolioList = () => {
    navigate("/portfolioList");
  };

  const navigateToAbout = () => {
    navigate("/");
  };

  return (
    <>
      <header>
        <Panel>
          <div className={css.menuWrap}>
            <div className={css.logo}>
              <Link to='/'><img src={Logo} alt="로고 이미지" /></Link>
            </div>
            <ul className={css.menu}>
              <li onClick={location.pathname === '/' ? onAboutClick : navigateToAbout} className={css.menuList}>
                <div>{$L('About Us')}</div>
              </li>
              <li onClick={location.pathname === '/' ? onPortfolioClick : navigateToportfolioList} className={css.menuList}>
                <div>{$L('Portfolio')}</div>
              </li>

              <li className={css.multilingualList}>
                <div className={css.multilingualSelectWrap} onClick={setDropdown}>
                  <img className={css.multilingualIcon} src={MultilingualIcon} alt="" />
                  <div className={css.multilingualSelector}>{multilingual[langIdx].name}</div>
                </div>
                <MultilingualDropdown visibility={dropdownVisibility}>
                  <ul className={css.languageDropdownUl}>
                    {multilingual.map((lang, idx) => (
                      <li key={`language-${idx}`}>
                        <button value={idx}
                          onClick={_onSelectLanguage}
                        >{lang.name}</button>
                      </li>
                    ))}
                  </ul>
                </MultilingualDropdown>
              </li>
            </ul>
          </div>
        </Panel>
      </header>
    </>
  )
}

export default Gnb;