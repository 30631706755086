import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Panel from '../../components/Panel';
import { $L } from '../../utils/common';

import PortfolioDetailData from '../../data/PortfolioDetailData';

import PortfolioDetailLogo from '../../assets/img/portfolio_list_logo.png';

import css from './PortfolioDetail.module.scss';
import { useParams } from 'react-router-dom';


const PortfolioDetail = ({ ...props }) => {
  const [previewCount, setPreviewCount] = useState(4);
  const [previewVisible, setPreviewVisible] = useState(false);
  const portfolioIndex = useParams().index;
  console.log(portfolioIndex)
  const itemInfo = PortfolioDetailData[portfolioIndex];
  console.log(PortfolioDetailData[1]);

  useEffect(() => {
    setPreviewCount(document.querySelectorAll(`.${css.preview}`).length);
  }, [previewCount]);
  console.log(document.querySelectorAll(`.${css.preview}`).length)

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`Portfolio - ${itemInfo?.mainTitle}` || "Portfolio Detail"}</title>
        </Helmet>
      </HelmetProvider>

      <article className={css.detailPortfolioIntro}
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url(${itemInfo.mainBackImg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
      >
        <Panel className={css.detailPortfolioPanel}>
          <div className={css.introWrap}>
            <em>{itemInfo.mainCategory}</em>
            <h2>{itemInfo.mainTitle}</h2>
            <p>{$L(itemInfo.mainDescription)}</p>
            {/* <button>홈페이지 바로가기</button> */}
            <ul>
              <li>
                <div className={css.introListInner}>
                  <div className={css.introTit}>Client</div>
                  <div className={css.introName}>{itemInfo.mainClient}</div>
                </div>
              </li>
              <li>
                <div className={css.introListInner}>
                  <div className={css.introTit}>Project</div>
                  <div className={css.introName}>{$L(itemInfo.mainProject)}</div>
                </div>
              </li>
              <li>
                <div className={css.introListInner}>
                  <div className={css.introTit}>Date</div>
                  <div className={css.introName}>{itemInfo.mainDate}</div>
                </div>
              </li>
            </ul>
          </div>
        </Panel>
      </article>

      <article className={`${css.detailPortfolioGoal} ${css.pt_90} ${css.pb_90}`}>
        <Panel>
          <h3><img src={PortfolioDetailLogo} alt="" />Project Goal</h3>
          <p className={css.goalContents}>{$L(itemInfo.goalDescription)}</p>
          <div className={css.goalWrap}>
            {itemInfo.goalList.map((goal, idx) => {
              console.log('goal...', goal, idx);
              return (
                <div className={css.goal} key={"goal" + idx} dangerouslySetInnerHTML={{ __html: $L(goal) }} />
              )
            })
            }
            <div className={css.loaderLineMask}>
              <div className={css.loaderLine}></div>
            </div>
          </div>
        </Panel>
      </article>

      <article className={`${css.detailPortfolioPreview} ${css.pt_90} ${css.pb_90}`}>
        <Panel>
          <h3>Project View</h3>
          <p>{$L(itemInfo.previewDescription)}</p>
          <div className={itemInfo === PortfolioDetailData[1] ? `${css.previewWrap} ${css.grid}` : `${css.previewWrap}`}>
            {/* 플러스 버튼 관련(이미지 개수에 따른 노출) */}
            {/* {previewList.map((preview, idx) => (
              <div className={`${css.preview} ${idx >= previewCount && !previewVisible ? css.hidden : ''}`} key={idx}><img src={preview.image} alt="" /></div>
            ))} */}
            {itemInfo.previewImg.map((preview, idx) => (
              <div className={css.preview}><img src={preview} alt="" /></div>
            ))}
          </div>
          {/* {previewCount >= 5 && (<button className={css.plus} onClick={() => setPreviewCount(!previewCount)}><img src={PlusBtn} alt="" /></button>)} */}
        </Panel>
      </article>
    </>
  )
}

export default PortfolioDetail;