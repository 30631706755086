import React, { useState, useEffect } from 'react';
import css from './MultilingualDropdown.module.scss';


const MultilingualDropdown = ({ visibility, children, ...props }) => {
  const [visibilityAnimation, setVisibilityAnimation] = useState(false);
  const [repeat, setRepeat] = useState(null);

  useEffect(() => {
    if (visibility) {
      clearTimeout(repeat);
      setRepeat(null);
      setVisibilityAnimation(true);
    } else {
      setRepeat(setTimeout(() => {
        setVisibilityAnimation(false);
      }, 400));
    }
  }, [visibility]);

  return (
    <>
      <article className={visibility ? css.slideFadeInDropdown : css.slideFadeOutDropdown}>
        {visibilityAnimation && children}
      </article>
    </>
	)
};
export default MultilingualDropdown;