import StringReSourceKO from '../data/StringReSourceKO.json';
import * as Config from '../data/Config';

const stringResources = { 'ko': StringReSourceKO };
const mConfig = Config.getConfig();

export const $L = (str) => {
  const resource = stringResources[mConfig.language];
  if (typeof str === 'object') {
    if (resource && resource[str.key]) {
      return resource[str.key].replace(/{br}/g, '\n');
    } else {
      str = str.value;
    }
  } else if (resource && resource[str]) {
    return resource[str].replace(/{br}/g, '\n');
  }

  return str && str.replace(/{br}/g, '\n');
};

export const getLanguageCode = () => {
  return navigator.language.split('-')[0]; //ko
};

/**
 * Generates a query string from the key/values of a JSON object
 * @param object The json object
 * @returns A value representing a URL compatible query string
 */
export const createQueryString = (object) => {
  const parts = [];
  for (const key of Object.getOwnPropertyNames(object)) {
    if (object[key] !== null && object[key] !== undefined && object[key] !== '') {
      parts.push(`${key}=${encodeURIComponent(object[key])}`);
    }
  }
  return parts.join('&');
};

export const createStringfyParams = (object) => {
  const parts = {};
  for (const key of Object.getOwnPropertyNames(object)) {
    if (object[key] !== null && object[key] !== undefined && object[key] !== '') {
      parts[key] = object[key].toString();
    }
  }
  return parts;
};

export const readSessionStorage = (key, defaultValue) => {
  const value = typeof window === 'object' && window.sessionStorage.getItem(key);
  if (!value && defaultValue !== undefined) {
    return defaultValue;
  }
  return value === 'undefined' ? null : JSON.parse(value);
};

export const writeSessionStorage = (key, value) => {
  if (typeof window === 'object') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const readLocalStorage = (key, defaultValue) => {
  const value = typeof window === 'object' && window.localStorage.getItem(key);
  if (!value && defaultValue !== undefined) {
    return defaultValue;
  }
  return value === 'undefined' ? null : JSON.parse(value);
};

export const writeLocalStorage = (key, value) => {
  if (typeof window === 'object') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const windowClose = () => {
  if (typeof window === 'object') {
    window.location.reload();
  }
};

export const convertUniToUtf8 = (str) => {
  try {
    return unescape(encodeURIComponent(str));
  } catch {
    return str;
  }
};
export const convertUtf8ToUni = (str) => {
  try {
    return decodeURIComponent(escape(str));
  } catch {
    return str;
  }
};
export const jsonConcat = (o1, o2) => {
  for (let key in o2) {
    o1[key] = o2[key];
  }
  return o1;
};

export const isMobile = () => {
  const fromThinq = readSessionStorage('fromThinQApp', false);
  const mobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return fromThinq || mobileUserAgent;
};

export const parseUriParamsData = () => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  const data = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    try {
      data[pair[0]] = JSON.parse(decodeURIComponent(pair[1]));
    } catch (err) {
      data[pair[0]] = decodeURIComponent(pair[1]);
    }
  }
  return data;
};

const JOB_HASH = {};

export const stopJob = (jobId) => {
  if (JOB_HASH[jobId]) {
    clearTimeout(JOB_HASH[jobId]);
    delete JOB_HASH[jobId];
  }
};

export const startJob = (jobId, func, delay = 100) => {
  stopJob(jobId);
  const jobKey = setTimeout(func, delay);
  JOB_HASH[jobId] = jobKey;
};
//////

const strTime = (s) => {
  return String(s).length === 1 ? '0' + String(s) : String(s);
};
export const timeToISO8601DateStr = (date) => {
  return date.getFullYear() + '-' + strTime(date.getMonth() + 1) + '-' + strTime(date.getDate());
};
// "2021-10-06T13:44:00"
export const timeToISO8601Str = (date) => {
  return (
    date.getFullYear() +
    '-' +
    strTime(date.getMonth() + 1) +
    '-' +
    strTime(date.getDate()) +
    'T' +
    strTime(date.getHours()) +
    ':' +
    strTime(date.getMinutes()) +
    ':' +
    strTime(date.getSeconds())
  );
};
export const SecondsToHoursMinutes = (seconds) => {
  if (typeof seconds === 'number') {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds - h * 3600) / 60);
    return [h, m];
  } else {
    return [];
  }
};
export const MinutesToHoursMinutes = (minute) => {
  if (typeof minute === 'number') {
    const h = Math.floor(minute / 60);
    const m = Math.floor(minute % 60);
    return [h, m];
  } else {
    return [];
  }
};
export const secondsToPlayTime = (second) => {
  if (typeof second === 'number') {
    let m = Math.floor(second / 60);
    let s = Math.floor(second % 60);
    if (m < 10) {
      m = '0' + m;
    }
    if (s < 10) {
      s = '0' + s;
    }
    return [m, s];
  } else {
    return [];
  }
};

export const reqHeader = () => {
  const headers = {};
  let xThinQData = window.gHeaderInfo.xThinQData;

  Object.keys(xThinQData).forEach(key => {
    headers[key.replace(/([A-Z])/g, " $1").split(' ').join('-').toLowerCase()] = xThinQData[key];
  })

  if (headers['x-emp-token']) {
    headers.Authorization = 'Bearer lckfjdojkvgdmfkfjkdflfjksd'
    return headers
  }
}

export const removeHtmlTag = (s) => {
  if (s) {
    return s.replace(/<\/?[^>]+(>|$)/g, "");
  }
  return s;
}