import { useEffect, useRef } from "react";
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import * as RouterPath from './data/RouterPath';
import HomeView from './views/Home';
import PortfolioListView from './views/Portfolio/PortfolioList';
import PortfolioDetailView from './views/Portfolio/PortfolioDetail';
import WalfareDetail from './views/WalfareDetail/WalfareDetail';
import Gnb from './components/Gnb/Gnb';
import Footer from './components/Footer/Footer';
import { useSelector } from "react-redux";

function App({ ...props }) {
  const { pathname } = useLocation();
  const { language } = useSelector((state) => state.common.appStatus);
  console.log(language)

  const AboutRef = useRef();
  const onAboutClick = () => {
    AboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const PortfolioRef = useRef();
  const onPortfolioClick = () => {
    PortfolioRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Gnb onAboutClick={onAboutClick} onPortfolioClick={onPortfolioClick} />
      <Routes>
        <Route path={RouterPath.ROOT} element={<HomeView AboutRef={AboutRef} PortfolioRef={PortfolioRef} />} />
        <Route path={RouterPath.PORTFOLIOLIST} element={<PortfolioListView />} />
        <Route path={RouterPath.PORTFOLIODETAIL + "/:index"} element={<PortfolioDetailView />} />
        <Route path={RouterPath.WALFAREDETAIL} element={<WalfareDetail />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
