import { Helmet, HelmetProvider } from "react-helmet-async"
import css from './Panel.module.scss';

const Panel = ({ children, ...props }) => {
  return (
    <>
      <div className={css.c_panel}>
        <div className={css.c_panelInner} {...props}>
          {children}
        </div>
      </div>
    </>
  )
}

export default Panel;