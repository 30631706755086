import React, { useState } from 'react';
import Panel from '../Panel';
import { $L } from '../../utils/common';

import FitnessIcon from '../../assets/img/splashIMG_moving.jpg';
import ThinQIcon from '../../assets/img/ThinQ.png';
import CandyIcon from '../../assets/img/candy_logo.svg';
import fitnessBg from '../../assets/img/fitness_bg.png'
import ThinQBg from '../../assets/img/thinQ_bg.png'
import CandyBg from '../../assets/img/candy_bg.png'
import css from './BannerSect.module.scss';

const BannerSect = ({ ...props }) => {

  const [currentTab, setCurrentTab] = useState(0);

  const selectMenuHandler = (idx) => {
    setCurrentTab(idx);
  };

  const portTabs = [
    {
      id: '1',
      title: 'LG Fitness',
      subtitle: $L('LG Smart TV APP - Home Training UX'),
      skill: $L('UX planning  .  design  .  VOD streaming server development'),
      icon: FitnessIcon,
      bg: fitnessBg
    },
    {
      id: '2',
      title: 'LG ThinQ',
      subtitle: $L('LG Home Appliance Control Mobile APP'),
      skill: $L('Publishing  .  React development'),
      icon: ThinQIcon,
      bg: ThinQBg
    },
    {
      id: '3',
      title: 'LG Candy',
      subtitle: $L('LG TV APP - Home Training VOD'),
      skill: $L('UX planning  .  design  .  VOD streaming server development'),
      icon: CandyIcon,
      bg: CandyBg
    }
  ]
  console.log(portTabs[currentTab]);

  return (
    <>
      <article className={css.bannerWrap}
        style={{ width: '100%', backgroundImage: `url(${portTabs[currentTab].bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}
      >
        <div className={css.bannerInner}>
          <Panel title="t-win">
            <div className={`${css.representativeWrap} ${css.last}`}>
              <div className={css.representativeInner}>
                <h2 className={css.representativeTit}>Representative</h2>
                <div className={css.representativeBlock}>
                  <div className={css.icon}>
                    <img src={portTabs[currentTab].icon} alt="아이콘 이미지" />
                  </div>
                  <div>
                    <h3>{portTabs[currentTab].title}</h3>
                    <p>{portTabs[currentTab].subtitle}</p>
                    <em>{portTabs[currentTab].skill}</em>
                  </div>
                </div>
                <div className={css.portTabWrap}>
                  {portTabs.map((portTab, idx) => (
                    <button className={idx === currentTab ? `${css.portTab} ${css.active}` : css.portTab}
                      onClick={() => selectMenuHandler(idx)}
                      key={idx}
                    >
                      <div className={css.portTabName}>{portTab.title}</div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </article>
    </>
  )
}

export default BannerSect;