import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Panel from '../Panel';
import { $L } from '../../utils/common';
import * as RouterPath from "../../data/RouterPath"
import PortfolioListData from '../../data/PortfolioListData.json';

import FitnessBg from '../../assets/img/fitness_bg.png'
import ThinQBg from '../../assets/img/thinQ_bg.png'
import CandyBg from '../../assets/img/candy_bg.png'
import FitBg from '../../assets/img/fit_bg.png'
import CommercialBg from '../../assets/img/commercial_bg.png'
import ChannelBg from '../../assets/img/channel_bg.png'
import PlusBtn from '../../assets/img/portfolio_sect_plus.png'
import css from './PortfolioSect.module.scss';

const ListItem = ({ title, bg }) => {
  const [isListHover, setIsListHover] = useState(false);
  const [item, setItem] = useState([]);

  // useEffect(() => {
  //   fetch(PortfolioListData)
  //     .then((res) => res.json())
  //     .then((data) => setItem(data));
  // }, []);

  return (
    <div className={isListHover ? `${css.list} ${css.hover}` : css.list}
      onMouseOver={() => setIsListHover(true)}
      onMouseOut={() => setIsListHover(false)}
      style={{ width: '100%', backgroundImage: isListHover ? `url(${bg})` : 'none', backgroundSize: isListHover ? '110%' : '100%', transition: 'background-size .3s ease', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', before: '#000' }}
    >
      <div className={isListHover ? `${css.listInner} ${css.hover}` : css.listInner}>
        <h3>{title}</h3>
      </div>
    </div>
  )
}

const PortfolioSect = ({ PortfolioRef, ...props }) => {
  const portfolioList = [
    {
      title: 'LG Fitness',
      bg: FitnessBg
    },
    {
      title: 'LG ThinQ App',
      bg: ThinQBg
    },
    {
      title: 'LG Candy',
      bg: CandyBg
    },
    {
      title: 'LG FiT TV',
      bg: FitBg
    },
    {
      title: 'Commercial TV Applications',
      bg: CommercialBg
    },
    {
      title: 'LG Channel+',
      bg: ChannelBg
    },
  ]

  return (
    <>
      <article className={`${css.mainPortfolioWrap} ${css.pt_90} ${css.pb_90}`} ref={PortfolioRef}>
        <Panel>
          <h2>{$L('Portfolio')}</h2>
          {/* <p>성공적인 프로젝트 수행을 위해 다양한 솔루션을 분석하고 연구합니다.</p> */}
          <div className={css.listWrap}>
            {portfolioList.map((portfolio, idx) => (
              <Link to={RouterPath.PORTFOLIODETAIL + '/' + idx} key={idx}>
                <ListItem title={portfolio.title} bg={portfolio.bg} />
              </Link>
            ))}
          </div>
          <div className={css.plus}>
            <Link to='/portfolioList'><img src={PlusBtn} alt="" /></Link>
          </div>
        </Panel>
      </article>
    </>
  )
}

export default PortfolioSect;