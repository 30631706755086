import React, { useState, useEffect } from 'react';
import Panel from '../../components/Panel';
import walfareDetailBalance from '../../assets/img/walfare_detail_balance.jpg';
import walfareDetailCulture from '../../assets/img/walfare_detail_culture.jpg';
import WalfareDetailPanorama from '../../assets/img/walfare_detail_panorama.jpg';

import css from './WalfareDetail.module.scss';

const WalfareDetail = ({ ...props }) => {

  const walfareList = [
    {
      thumbnail: walfareDetailBalance,
      title: '유연 근무 환경 및 워라벨 시행',
      discription: '일할 땐 일하고 쉴 땐 쉬자~!!',
      infoList: ["워라벨을 중시하고, 자유로운 업무 환경을 추구하는 사내 문화", "월~목 근무시간 : 09:00 ~ 18:00", "매주 금요일 일가정 양립 및 워라벨을 위해 <span>1시간 조기 퇴근</span>하는 <span style='color: red; font-style: italic;'>'얼~리 아웃데이!'</span> 시행", "사업장 및 부서별 업무 상황에 따른 유연 근무제 시행", "자유로운 연/월차 및 반차 사용", "임신/육아/가정 등 여성들의 건강한 삶을 위한 유연 근무제 시행", "개인(가정) 이사 및 <span>생일/결혼기념일</span>에 1일 유급 휴가 별도 제공", "차량 출퇴근자들을 위한 정기 <span>주차 등록</span> 제공"]
    },
    {
      thumbnail: walfareDetailCulture,
      title: '사내 복지 및 문화',
      discription: '잘먹은 직원, 때깔도 좋다~!',
      infoList: ["'오늘 점심은 뭐 먹지~??'  메뉴/비용 걱정 없는 점심식사 문화", "점심 걱정 No~!! 전 직원, <span>구내식당 점심 쿠폰</span> 제공", "외부식당 이용 시 점심식사비 제공", "당 충전은 수시로~, <span>푸짐한 간식거리들</span>과 각종 음료 제공"]
    },
    {
      thumbnail: WalfareDetailPanorama,
      title: '주변 전경',
      discription: '광교테크노밸리의 다양한 공공기관 및 연구기관들 함께, 탁 트인 주변 경관과 쾌적하고 편리한 인프라 환경~!'
    }
  ]

  return (
    <>
      <article className={`${css.walfareDetailWrap} ${css.pb_90}`}>
        <Panel>
          <h3 className={css.walfareDetailTit}>근무/복지 제도 및 문화</h3>
          <ul className={css.walfareListWrap}>
            {walfareList.map((walfare, idx) => (
              <li className={css.walfareList}>
                <div className={css.listInner}>
                  <img className={css.thumbnail} src={walfare.thumbnail} alt="" />
                  <div className={css.contents}>
                    <h4>{walfare.title}</h4>
                    <p>{walfare.discription}</p>
                    <ul className={css.infoListWrap}>
                      {walfare.infoList && walfare.infoList.map((info, idx) =>
                        <li className={css.infoList} key={"walfareInfo" + idx} dangerouslySetInnerHTML={{ __html: info }} />
                      )}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Panel>
      </article>
    </>
  )
}

export default WalfareDetail;