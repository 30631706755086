import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Panel from '../../components/Panel';
import PortfolioListData from '../../data/PortfolioListData.json';
import * as RouterPath from "../../data/RouterPath"
import { $L } from '../../utils/common';

import PortfolioListBannerLogo from '../../assets/img/portfolio_list_banner_logo.png'
import PortfolioListLogo from '../../assets/img/portfolio_list_logo.png'

import FitnessThumbnail from '../../assets/img/portfolio_list_fitness_thumbnail.png'
import ThinQThumbnail from '../../assets/img/portfolio_list_thinQ_thumbnail.png'
import CandyThumbnail from '../../assets/img/portfolio_list_candy_thumbnail.png'
import FitThumbnail from '../../assets/img/portfolio_list_fit_thumbnail.png'
import CommercialThumbnail from '../../assets/img/portfolio_list_commercial_thumbnail.png'
import ChannelThumbnail from '../../assets/img/portfolio_list_channel_thumbnail.png'

import Android from '../../assets/img/portfolio_list_android_icon.png'
import Ios from '../../assets/img/portfolio_list_ios_icon.png'
import Design from '../../assets/img/portfolio_list_design.png'
import Publishing from '../../assets/img/portfolio_list_publishing.png'
import UX from '../../assets/img/portfolio_list_ux_icon.png'
import Web from '../../assets/img/portfolio_list_web_icon.png'

import css from './PortfolioList.module.scss';

const PortfolioList = ({ ...props }) => {

  const [item, setItem] = useState([]);

  useEffect(() => {
    fetch(PortfolioListData)
      .then((res) => res.json())
      .then((data) => setItem(data));
  }, []);
  console.log(item);

  const portfolioList = [
    {
      thumbnail: FitnessThumbnail,
      title: 'LG Fitness',
      discription: $L('It is a VOD service that allows you to follow simple exercises at home through LG Smart TV.'),
      iconList: [
        {
          icon: UX,
          iconName: 'UX'
        },
        {
          icon: Design,
          iconName: 'Design'
        },
        {
          icon: Publishing,
          iconName: 'Publishing'
        },
        {
          icon: Web,
          iconName: 'Web'
        }
      ]
    },
    {
      thumbnail: ThinQThumbnail,
      title: 'LG ThinQ App',
      discription: $L('The LG ThinQ App is an application that allows you to conveniently control your home appliances with one app to make your daily life more convenient.'),
      iconList: [
        {
          icon: Android,
          iconName: 'Android'
        },
        {
          icon: Ios,
          iconName: 'ios'
        },
        {
          icon: Publishing,
          iconName: 'Publishing'
        },
        {
          icon: Web,
          iconName: 'Web'
        }
      ]
    },
    {
      thumbnail: CandyThumbnail,
      title: 'LG CANDY',
      discription: $L('It is a VOD service that allows you to follow simple exercises at home through an LG smart TV or mobile phone app. UX planning, design, and development of previously developed Mobile App exclusively for TV.'),
      iconList: [
        {
          icon: UX,
          iconName: 'UX'
        },
        {
          icon: Design,
          iconName: 'Design'
        },
        {
          icon: Publishing,
          iconName: 'Publishing'
        },
        {
          icon: Web,
          iconName: 'Web'
        }
      ]
    },
    {
      thumbnail: FitThumbnail,
      title: 'LG FiT TV',
      discription: $L("It is a service that helps you maintain a healthy body by conducting body measurements and tests at home through LG Smart TV. App's UX planning, design, and development."),
      iconList: [
        {
          icon: UX,
          iconName: 'UX'
        },
        {
          icon: Design,
          iconName: 'Design'
        },
        {
          icon: Publishing,
          iconName: 'Publishing'
        },
        {
          icon: Web,
          iconName: 'Web'
        }
      ]
    },
    {
      thumbnail: CommercialThumbnail,
      title: 'Commercial TV Applications',
      discription: $L('UI of most commercial applications such as FirstUse, Settings, and Installation has been implemented, and it has been applied to Hotel, Signage, and Hospital-oriented TVs.'),
      iconList: [
        {
          icon: Publishing,
          iconName: 'Publishing'
        },
        {
          icon: Web,
          iconName: 'Web'
        }
      ]
    },
    {
      thumbnail: ChannelThumbnail,
      title: 'LG Channel +',
      discription: $L("LG Channel is Korea's first LG Smart TV service that allows you to watch more than 50 paid channels together with terrestrial channels without any additional equipment."),
      iconList: [
        {
          icon: Publishing,
          iconName: 'Publishing'
        },
        {
          icon: Web,
          iconName: 'Web'
        }
      ]
    }
  ]

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Portfolio</title>
        </Helmet>
      </HelmetProvider>

      <article className={`${css.listPortfolioWrap} ${css.pb_90}`}>
        <div className={css.portfolioListBanner}>
          <div className={css.PortfolioListLogoWrap}>
            <img src={PortfolioListBannerLogo} alt="" />
            <h2>{$L('Portfolio')}</h2>
          </div>
        </div>
        <Panel>
          <h3 className={css.PortfolioListLogo}><img src={PortfolioListLogo} alt="" />Portflio</h3>
          <p className={css.PortfolioListDesc}>{$L('Check out the newly updated UX service of T-WIN.')}</p>
          <ul>
            {portfolioList.map((portfolio, idx) => (
              <li>
                <div className={css.listInner}>
                  <Link to={RouterPath.PORTFOLIODETAIL + '/' + idx}><img className={css.thumbnail} src={portfolio.thumbnail} alt="" /></Link>
                  <div className={css.contents}>
                    <Link to={RouterPath.PORTFOLIODETAIL + '/' + idx}><h3>{portfolio.title}</h3></Link>
                    <p>{portfolio.discription}</p>
                    <div className={css.skillWrap}>
                      {portfolio.iconList && portfolio.iconList.map((icon, idx) =>
                        <div className={css.skill}>
                          <img className={css.icon} src={icon.icon} alt="" />
                          <em className={css.iconName}>{icon.iconName}</em>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
            {/* {PortfolioListData.data.map((detail, idx) => (
              <>
                <div>{detail.title}</div>
                <img src={detail.imgsrc} alt="" />
              </>
            ))} */}
          </ul>
        </Panel>
      </article>
    </>
  )
}

export default PortfolioList;