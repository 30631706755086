import { Helmet, HelmetProvider } from 'react-helmet-async';
import BannerSect from '../components/Home/BannerSect';
import IntroduceSect from '../components/Home/IntroduceSect';
import PortfolioSect from '../components/Home/PortfolioSect';
import StorySect from '../components/Home/StorySect';

const Home = ({ AboutRef, PortfolioRef, ...props }) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>T-WIN</title>
        </Helmet>
      </HelmetProvider>

      <BannerSect />
      <IntroduceSect AboutRef={AboutRef} />
      <PortfolioSect PortfolioRef={PortfolioRef} />
      <StorySect />
    </>
  )
}

export default Home;