import fitness_bg from "../assets/img/fitness_bg.png";
import thinQ_bg from "../assets/img/thinQ_bg.png";
import candy_bg from "../assets/img/candy_bg.png";
import fit_bg from "../assets/img/fit_bg.png";
import commercial_bg from "../assets/img/commercial_bg.png";
import channel_bg from "../assets/img/channel_bg.png";

import portfolio_detail_fitness01 from "../assets/img/portfolio_detail_fitness01.jpg";
import portfolio_detail_fitness02 from "../assets/img/portfolio_detail_fitness02.jpg";
import portfolio_detail_fitness03 from "../assets/img/portfolio_detail_fitness03.jpg";
import portfolio_detail_fitness04 from "../assets/img/portfolio_detail_fitness04.jpg";
import portfolio_detail_fitness05 from "../assets/img/portfolio_detail_fitness05.jpg";
import portfolio_detail_fitness06 from "../assets/img/portfolio_detail_fitness06.jpg";

import portfolio_detail_thinq01 from "../assets/img/portfolio_detail_thinq01.jpg";
import portfolio_detail_thinq02 from "../assets/img/portfolio_detail_thinq02.jpg";
import portfolio_detail_thinq03 from "../assets/img/portfolio_detail_thinq03.jpg";
import portfolio_detail_thinq04 from "../assets/img/portfolio_detail_thinq04.jpg";
import portfolio_detail_thinq05 from "../assets/img/portfolio_detail_thinq05.jpg";
import portfolio_detail_thinq06 from "../assets/img/portfolio_detail_thinq06.jpg";


import portfolio_detail_candy01 from "../assets/img/portfolio_detail_candy01.jpg";
import portfolio_detail_candy02 from "../assets/img/portfolio_detail_candy02.jpg";
import portfolio_detail_candy03 from "../assets/img/portfolio_detail_candy03.jpg";
import portfolio_detail_candy04 from "../assets/img/portfolio_detail_candy04.jpg";
import portfolio_detail_candy05 from "../assets/img/portfolio_detail_candy05.jpg";
import portfolio_detail_candy06 from "../assets/img/portfolio_detail_candy06.jpg";


import portfolio_detail_fit01 from "../assets/img/portfolio_detail_fit01.jpg";
import portfolio_detail_fit02 from "../assets/img/portfolio_detail_fit02.jpg";
import portfolio_detail_fit03 from "../assets/img/portfolio_detail_fit03.jpg";
import portfolio_detail_fit04 from "../assets/img/portfolio_detail_fit04.jpg";
import portfolio_detail_fit05 from "../assets/img/portfolio_detail_fit05.jpg";
import portfolio_detail_fit06 from "../assets/img/portfolio_detail_fit06.jpg";

import portfolio_detail_commercial01 from "../assets/img/portfolio_detail_commercial01.jpg";
import portfolio_detail_commercial02 from "../assets/img/portfolio_detail_commercial02.jpg";
import portfolio_detail_commercial03 from "../assets/img/portfolio_detail_commercial03.jpg";
import portfolio_detail_commercial04 from "../assets/img/portfolio_detail_commercial04.jpg";
import portfolio_detail_commercial05 from "../assets/img/portfolio_detail_commercial05.jpg";
import portfolio_detail_commercial06 from "../assets/img/portfolio_detail_commercial06.jpg";

import portfolio_detail_channels01 from "../assets/img/portfolio_detail_channels01.jpg";
import portfolio_detail_channels02 from "../assets/img/portfolio_detail_channels02.jpg";
import portfolio_detail_channels03 from "../assets/img/portfolio_detail_channels03.jpg";
import portfolio_detail_channels04 from "../assets/img/portfolio_detail_channels04.jpg";



const PortfolioDetailData = [
  {
    mainCategory: 'TV Application',
    mainTitle: 'LG Fitness',
    mainDescription: 'LG Smart TV APP - Home Training UX',
    mainClient: 'LGE',
    mainProject: 'App & Server Development',
    mainDate: '2021',
    mainBackImg: fitness_bg,
    goalDescription: "VOD service that allows users to easily follow exercise routines at home through LG Smart TV. We developed the app's UX design, development, and VOD streaming server.",
    goalList: ["<p>Easy exercise<br/>at home</p>", "<p>Creating<br/>my own<br/>exercise routine</p>", "<p>Having fun with<br/>YouTube content</p>"],
    previewDescription: 'UX planning & design . VOD streaming server and app development',
    previewImg: [portfolio_detail_fitness01, portfolio_detail_fitness02, portfolio_detail_fitness03, portfolio_detail_fitness04, portfolio_detail_fitness05, portfolio_detail_fitness06]
  },
  {
    mainCategory: 'Mobile Application',
    mainTitle: 'LG ThinQ App',
    mainDescription: 'LG Home Appliance Control Mobile APP',
    mainClient: 'LGE',
    mainProject: 'Mobile App Development',
    mainDate: '2021',
    mainBackImg: thinQ_bg,
    goalDescription: 'The LG ThinQ App is an application that makes daily life more convenient by allowing easy control of home appliances with just one app.',
    goalList: ["<p>LG ThinQ<br />appliance care</p>", "<p>Smart<br />diagnosis</p>", "<p>Best<br />life-cycle care</p>"],
    previewDescription: 'Publishing . React development',
    previewImg: [portfolio_detail_thinq01, portfolio_detail_thinq02, portfolio_detail_thinq03, portfolio_detail_thinq04, portfolio_detail_thinq05, portfolio_detail_thinq06]
  },
  {
    mainCategory: 'TV Application',
    mainTitle: 'LG CANDY',
    mainDescription: 'LG TV APP - Home Training VOD',
    mainClient: 'LGE',
    mainProject: 'Design & Development',
    mainDate: '2022',
    mainBackImg: candy_bg,
    goalDescription: 'VOD service that allows you to follow simple exercises at home through an LG smart TV or mobile phone app. UX planning, design, and development of previously developed Mobile App exclusively for TV.',
    goalList: ["<p>Simple workout<br />at home</p>", "<p>Momentum<br />analysis through<br />band linkage</p>", "<p>Goal setting &<br />schedule<br />management</p>"],
    previewDescription: 'UX planning, design and app development',
    previewImg: [portfolio_detail_candy01, portfolio_detail_candy02, portfolio_detail_candy03, portfolio_detail_candy04, portfolio_detail_candy05, portfolio_detail_candy06]
  },
  {
    mainCategory: 'TV Application',
    mainTitle: 'LG FiT TV',
    mainDescription: 'Senior Fitness application for LG Smart TV',
    mainClient: 'LGE',
    mainProject: 'Design & App Development',
    mainDate: '2022',
    mainBackImg: fit_bg,
    goalDescription: "A service that helps you maintain a healthy body by conducting body measurements and tests at home through LG Smart TV. App's UX planning, design, and development.",
    goalList: ["<p>Physical Exam.<br />& Posture<br />Correction</p>", "<p>Home health<br />care program</p>", "<p>Virtual trekking<br />workout</p>"],
    previewDescription: 'UX planning, design and app development',
    previewImg: [portfolio_detail_fit01, portfolio_detail_fit02, portfolio_detail_fit03, portfolio_detail_fit04, portfolio_detail_fit05, portfolio_detail_fit06]
  },
  {
    mainCategory: 'TV Application',
    mainTitle: 'Commercial TV Applications',
    mainDescription: 'WebOS based system applications',
    mainClient: 'LGE',
    mainProject: 'App Development',
    mainDate: '2020',
    mainBackImg: commercial_bg,
    goalDescription: 'UI of most commercial applications such as FirstUse, Settings, and Installation has been implemented, and it has been applied to Hotel, Signage, and Hospital-oriented TVs.',
    goalList: ["<p>Signage TV</p>", "<p>Hotel TV</p>", "<p>Hospital TV</p>"],
    previewDescription: 'UX/UI planning, design, and app development',
    previewImg: [portfolio_detail_commercial01, portfolio_detail_commercial02, portfolio_detail_commercial03, portfolio_detail_commercial04, portfolio_detail_commercial05, portfolio_detail_commercial06]
  },
  {
    mainCategory: 'TV Application',
    mainTitle: 'LG Channel +',
    mainDescription: 'LG smart TV service application for 50+ paid channels',
    mainClient: 'LGE',
    mainProject: 'App Development',
    mainDate: '2020',
    mainBackImg: channel_bg,
    goalDescription: 'LG Channel is Korean first LG Smart TV service that allows you to watch more than 50 paid channels together with terrestrial channels without any additional equipment.',
    goalList: ["<p>Terrestrial TV<br />service</p>", "<p>50+ paid<br />channels</p>", "<p>Channel<br />management &<br />preview UI</p>"],
    previewDescription: 'UX planning, design . Service components design and app development',
    previewImg: [portfolio_detail_channels01, portfolio_detail_channels02, portfolio_detail_channels03, portfolio_detail_channels04]
  }
]
export default PortfolioDetailData;