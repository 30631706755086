/**
 * Footer
 *
 * @module Footer
 */

import Panel from '../Panel';
import { Link } from 'react-router-dom';
import { $L } from '../../utils/common';
import Logo from '../../assets/img/footer_logo.png';
import Tel from '../../assets/img/footer_tel.png';
import Email from '../../assets/img/footer_email.png';
import Fax from '../../assets/img/footer_fax.png';
import IdCard from '../../assets/img/footer_id_card.png';
import Location from '../../assets/img/footer_location.png';
import css from './Footer.module.scss';

const Footer = ({ ...props }) => {

  const companyInfoLeft = [
    {
      icon: Tel,
      cont: 'TEL. 031.890.5801'
    },
    {
      icon: Fax,
      cont: 'FAX. 031.624.5794'
    }
  ]

  const companyInfoRight = [
    {
      icon: Email,
      cont: 'E-MAIL. t.win@t-win.kr'
    },
    {
      icon: IdCard,
      cont: $L('Business Registration. 651-81-00883')
    },
    {
      icon: Location,
      cont: $L('37, Poeun-daero 59beon-gil, Suji-gu, Yongin-si, Gyeonggi-do, Republic of Korea')
    }
  ]

  return (
    <>
      <footer className={css.footer}>
        <Panel>
          <div className={css.footerInner}>
            <div className={css.footerTop}>
              <div className={css.ftrlogo}>
                <Link to='/'><img src={Logo} alt="로고 이미지" /></Link>
              </div>
            </div>
            <div className={css.footerMiddle}>
              <div className={css.companyInfoLeft}>
                {companyInfoLeft.map((info, idx) => (
                  <div className={css.companyInfoWrap} key={idx}>
                    <div className={css.companyInfo}>
                      <img className={css.icon} src={info.icon} alt="" />
                      <div className={css.cont}>{info.cont}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={css.companyInfoRight}>
                {companyInfoRight.map((info, idx) => (
                  <div className={css.companyInfoWrap} key={idx}>
                    <div className={css.companyInfo}>
                      <img className={css.icon} src={info.icon} alt="" />
                      <div className={css.cont}>{info.cont}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={css.footerBottom}>
              <div>Ⓒ 2022 TWIN Co. Ltd. All rights reserved.</div>
            </div>
          </div>
        </Panel>
      </footer>
    </>
  )
}

export default Footer;