import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Panel from '../Panel';
import { $L } from '../../utils/common';
import companyImg01 from '../../assets/img/company_img01.jpg';
import companyImg02 from '../../assets/img/company_img02.png';
import companyImg03 from '../../assets/img/company_img03.png';
import companyImg04 from '../../assets/img/company_img04.png';
import companyImg05 from '../../assets/img/company_img05.png';
import companyImg06 from '../../assets/img/company_img06.png';
import companyImg07 from '../../assets/img/company_img07.png';
import companyImg08 from '../../assets/img/company_img08.png';
import walfareDetailMovementIcon from '../../assets/img/walfare_detail_movement_icon.png';
import css from './StorySect.module.scss';


//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const StorySect = ({ ...props }) => {

  const [slidesViewNumber, setSlidesViewNumber] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const companyImg = [
    {
      img: companyImg01
    },
    {
      img: companyImg02
    },
    {
      img: companyImg03
    },
    {
      img: companyImg04
    },
    {
      img: companyImg05
    },
    {
      img: companyImg06
    },
    {
      img: companyImg07
    },
    {
      img: companyImg08
    }
  ]

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let newNumber = 1;

    if (screenWidth >= 1600) {
      newNumber = 5.3;
    } else if (screenWidth >= 1200) {
      newNumber = 4.3;
    } else if (screenWidth >= 992) {
      newNumber = 3.3;
    } else if (screenWidth >= 768) {
      newNumber = 2.3;
    } else if (screenWidth >= 680) {
      newNumber = 2.3;
    } else if (screenWidth >= 550) {
      newNumber = 1.7;
    } else if (screenWidth >= 350) {
      newNumber = 1.3;
    }

    setSlidesViewNumber(newNumber);
  }, [screenWidth]);

  return (
    <>
      <article className={`${css.storyWrap} ${css.pt_90} ${css.pb_90}`} >
        <Panel>
          <div className={css.storyDescription}>
            <h2>{$L("people of T-WIN")}</h2>
            <p>"{$L("We look forward to your challenges and passion in creating T-WIN's future.")}<br />{$L("Let's create a new media world and enjoyable experiences together with a positive mindset.")}"</p>
            {/* <Link to='/walfareDetail'>
              <img src={walfareDetailMovementIcon} alt="" />
              {$L("Culture of Work & Walfare")}
            </Link> */}
          </div>
          <div className={css.swiperWrap}>
            <div className={css.swiperInner}>
              <Swiper
                slidesPerView={slidesViewNumber}
                spaceBetween={30}
                className={css.mySwiper}
              >
                {companyImg.map((company, idx) => (
                  <SwiperSlide key={idx}>
                    <div className={css.companyImg}>
                      <img src={company.img} alt="" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </Panel>
      </article>
    </>
  )
}

export default StorySect;