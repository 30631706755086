import Panel from '../Panel';
import { $L } from '../../utils/common';

import css from './IntroduceSect.module.scss';

const IntroduceSect = ({ AboutRef, ...props }) => {

  const slider = [
    {
      id: '1',
      title: <h3>Mobile<br />Device</h3>
    },
    {
      id: '2',
      title: <h3>Smart<br />TV</h3>
    },
    {
      id: '3',
      title: <h3>Voice AI<br />&<br />Streaming</h3>
    },
    {
      id: '4',
      title: <h3>Car<br />Infotainment</h3>
    },
  ]

  return (
    <>
      <article className={css.introduceWrap} ref={AboutRef}>
        <div className={`${css.introduceInner} ${css.pt_90} ${css.pb_90}`}>
          <Panel>
            <div className={css.introduceListWrap}>
              <div className={css.introduceList}>
                <div className={css.descriptionWrap}>
                  <h2>{$L('T-WIN, a developer specializing in UX services')}</h2>
                  {/* <p>기술만을 위한 개발은 하지 않습니다.<br />사람과 기술을 이어주는 것이 디자인이라 생각하고,<br />그 디자인을 완성시켜주는 수단으로의 기술 개발에 집중합니다.</p> */}
                  <p>{$L("T-WIN is a venture firm that specializes in service and solution construction for UX/UI, AI learning, and embedded software such as smart TVs.")}</p>
                </div>
                <div className={css.deviceWrap}>
                  {slider.map((slide, idx) => (
                    <div className={css.device} key={idx}>
                      {slide.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </article>
    </>
  )
}

export default IntroduceSect;