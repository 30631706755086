import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appStatus: {
    captionEnable: false,
    language: "EN"
  }
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    changeAppStatus: (state, action) => {
      let isUpdated = false;
      for (let i in action.payload) {
        if (typeof action.payload[i] === 'object') {
          if (JSON.stringify(action.payload[i]) !== JSON.stringify(state.appStatus[i])) {
            isUpdated = true;
            break;
          }
        } else if (state.appStatus[i] !== action.payload[i]) {
          isUpdated = true;
          break;
        }
      }
      if (isUpdated) {
        state.appStatus = { ...state.appStatus, ...action.payload };
      }
    },
    changeLocalSettings: (state, action) => {
      state.localSettings = { ...state.localSettings, ...action.payload };
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeAppStatus,
  changeLocalSettings,
} = commonSlice.actions;

export default commonSlice.reducer;
