export const CMS_AUTHORIZATION = 'Bearer lckfjdojkvgdmfkfjkdflfjksd';

const HOMEGYM_BASEURL_DEV = "https://qt2-kic.homegym.lgappstv.com/api";

class Config {
  constructor() {
    this.backendMode = "OP";
    this.language = 'en';
  }
  setLanguage = (lang) => {
    this.language = lang;
  };
  getLanguage = () => {
    return this.language;
  };
}
let mConfig = new Config();
export const getConfig = () => {
  return mConfig;
}